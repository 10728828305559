// Only code specific to this site should be here. Anything that can be used across multiple sites
// should be pushed into the KMS.
// const bootstrap = window.bootstrap;
import Splide from "@splidejs/splide";
import {FloatLabels} from "@keodesign/float-labels";

export function init() {
	setupMapClick();
	setupTestimonialSlider();
	new FloatLabels(".js-float-wrap");
}


export function setupMapClick(){
	const map_click_list = document.querySelectorAll("#map-click, .map-click");
	if (map_click_list) {
		map_click_list.forEach((map_click) => {
			map_click.addEventListener("click", () => {
				// shield the map on mobile for easy scrolling
				map_click.style.display = "none";
			});
		});
	}
}


export function setupTestimonialSlider(){
	var testimonialSlider = document.querySelectorAll(".testimonial-slider");
	
	if(testimonialSlider){
		for (var i = 0; i < testimonialSlider.length; i++ ) {
			var slider = testimonialSlider[i].querySelector(".slider");
			
			new Splide( slider, {
				type   : "fade",
				rewind    : true,
				pagination: true,
				arrows    : false,
				autoplay: true,
			}).mount();
		}
	}
}

window.addEventListener("DOMContentLoaded", init);